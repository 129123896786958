import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  constructor(public router: Router, translate: TranslateService){
    this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
          gtag('config', 'G-SF7J1E5E9K',
            {
              'page_path': event.urlAfterRedirects
            }
          );
          fbq('track', 'PageView');
        }
      }
    );
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('nl');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('nl');
  }

  title = 'templify';
}
