import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public isMenuCollapsed = true;
  public shrinkHeader: boolean = false;
  public currentLang = '';
  public languages = [
    {
      text: 'English',
      value: 'english'
    },
    {
      text: 'Nederlands',
      value: 'dutch'
    }
  ];
    menu = [];

  constructor(public commonService: CommonService, public translate: TranslateService, private router: Router){
    translate.get('menu').subscribe(
      res => {
        this.menu = res;
      }
    );
  }

  clickToScroll(item: any): void {
    this.commonService.scrollTo(item);
    if (window.innerWidth <= 991) {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    }
  }

  ngOnInit(): void {
    this.animateHeader();
  }

  animateHeader(): void {
    window.onscroll = () => {
      if (window.pageYOffset > 120) {
        this.shrinkHeader = true;
      } else {
        this.shrinkHeader = false;
      }
    };
  }

  langChange(value): void{
    this.translate.setDefaultLang(value);
    this.currentLang = value;
  }

  navigate(scrollTo:any): void{

    this.router.navigate(['/home']);
    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.clickToScroll(scrollTo);
    }, 500);

  }

}
