<div class="header-wrap" [class.scrolled]="shrinkHeader">
  <div class="orange h-top">
    <div class="container">
      <div class="text-right py-2">
        <span class="mr-lg-4 mr-2">info@templify.nl</span>
        <span class="mr-lg-4 mr-2">020-3695533</span>
        <!-- <span class="language-switch">
          <i class="fas fa-angle-down"></i>
          <select
            #selectLang
            (change)="langChange(selectLang.value)"
            class="lang-select"
          >
            <option
              *ngFor="let lang of languages"
              [value]="lang.value"
              [selected]="lang.value == current_lang"
            >
              {{ lang.text }}
            </option>
          </select>
        </span> -->
      </div>
    </div>
  </div>
  <header class="app-header">
    <nav class="navbar navbar-expand-lg bg-white">
      <div class="container">
        <a routerLink="/" class="navbar-brand"><img src="assets/images/Templify-logo-slogan.png" alt="" /></a>
        <button class="navbar-toggler bg-light" (click)="isMenuCollapsed = !isMenuCollapsed">
          <span class="fas fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
          <ul *ngIf="!commonService.isOtherScreen" class="navbar-nav ml-auto mr-3">
            <li class="nav-item" *ngFor="let item of menu">
              <a (click)="clickToScroll(item.scrollTo)" [ngClass]="{
                  active: commonService.currentActive === item.currentActive
                }" class="nav-link">{{ item.text }}</a>
            </li>
          </ul>

          <ul *ngIf="commonService.isOtherScreen" class="navbar-nav ml-auto mr-3">
            <li class="nav-item" *ngFor="let item of menu">
              <a (click)="navigate(item.scrollTo)" class="nav-link">{{ item.text }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</div>
